import React, { Fragment } from 'react';

const renderChild = (isVisible, className) => child => {
    const classes = isVisible ? `${child.props.className} ${className}` : child.props.className;
    return React.cloneElement(child, { className: classes });
}

const InViewportInner = ({ isVisible, addClassName, children }) => {
    return (
        <Fragment>
            {React.Children.map(children, renderChild(isVisible, addClassName))}
        </Fragment>
    );
}

export default InViewportInner;