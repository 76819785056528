module.exports = {
  languages: ["pt"],
  defaultLanguage: "pt",
  apiPath: "http://localhost:3006",
  localePath: "/locales",
  imagePath: "/images",
  preloadLanguageBlocks: ["common"],
  websiteName: "masterCassino",
  supportedTabs: ["home", "faq"]
};
