import React , {useState, useEffect, useCallback} from 'react';
import { useTranslation } from 'core';
import InViewport from '../common/InViewport/InViewport';
import config from '../../configs/config';
import { replaceTranslationVariables } from '../../helpers/format';
import Feature from './Feature';

import './home.scss';
import icon1 from "../../images/game-icons/Combined Shape-2@1.5x.svg";
import icon2 from "../../images/game-icons/Combined Shape Copy@1.5x.svg";
import icon3 from "../../images/game-icons/Group 26@1.5x.svg";
import icon4 from "../../images/game-icons/Combined Shape Copy 2@1.5x.svg";
import icon5 from "../../images/game-icons/Football@1.5x.svg";
import icon6 from "../../images/game-icons/Ball@1.5x.svg";
import icon7 from "../../images/game-icons/Combined Shape-1@1.5x.svg";
import icon8 from "../../images/game-icons/HAnd@1.5x.svg";

import icon9 from "../../images/game-icons/Combined Shape Copy 3@1.5x.svg";
import icon10 from "../../images/game-icons/Combined Shape Copy 4@1.5x.svg";
import icon11 from "../../images/game-icons/Combined Shape Copy 6@1.5x.svg";
import icon12 from "../../images/game-icons/stats@1.5x.svg";
import icon13 from "../../images/game-icons/Combined Shape Copy 5@1.5x.svg";
import icon14 from "../../images/game-icons/animations@1.5x.svg";
import icon15 from "../../images/game-icons/Combined Shape@1.5x.svg";
import icon16 from "../../images/game-icons/plate@1.5x.svg";

const openPokerApp = (e) => {
    e.preventDefault();
    const width = 400;
    const height = 800;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    window.open('https://clubapp.clubdaddy.net', '', [
    'width=' + width,
    'height=' + height,
    'resizable=0',
    'scrollbars=1',
    'top=' + (screenHeight - height - 100) / 2,
    'left=' + (screenWidth - width) / 2
    ].toString());
}

const Home = () => {
    const t = useTranslation('common');
    const clubName = config.websiteName;

    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        yourMessage: ''
    });

    const onHandleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const sendFormToApi = useCallback(() => {
        console.log('send form info = ', formState );
    })

    useEffect(() => {
        console.log("formState = ", formState);
    },[formState]);


  return (
      <div className="homepage-container">
          <div className="section-b v-1">
              <div className="home-main-section">
                  <div className="left-t-col">
                      <div className="top-banner-text-b">
                          <InViewport addClassName="untitle-me">
                            <h1 className="title-text-v-1">
                                {t(clubName)}- <br/> {t('freeOnlineGames')}
                            </h1>
                          </InViewport>
                          <p className="b-ap-form-text">
                              {t('freeOnlineGamesDesc')} <br/>
                              {replaceTranslationVariables(t('freeOnlineGamesDesc1'),{"clubName": clubName})}
                          </p>
                      </div>
                      <div className="download-app-section">
                          <h2 className="mini-b-title">{t('comingSoonOn')}</h2>
                          <div className="app-button-row">
                              <div className="app-button-v">
                                  <div className="app-icon-v">
                                      <i className="icon-apple15x"/>
                                  </div>
                                  <p className="app-name-v">{t('appStore')}</p>
                              </div>
                              <div className="app-button-v">
                                  <div className="app-icon-v">
                                      <i className="icon-android15x"/>
                                  </div>
                                  <p className="app-name-v">{t('googlePlay')}</p>
                              </div>
                          </div>
                          <div className="private-club-b">
                              <h2 className="play-b-title-t">{replaceTranslationVariables(t('availableClubs'), {"clubName": clubName})}</h2>
                              <a href="https://clubapp.clubdaddy.net" target="_blank" className="btn-v bg-color big" onClick={openPokerApp}>{(t('playOnline'))}</a>
                          </div>
                      </div>
                  </div>
                  <div className="right-img-col">
                      <div className="img-t-b" id="home"/>
                  </div>
              </div>
          </div>
          <div className="section-b v-2">
              <div className="b-center">
                  <div className="chip-bg-v">
                      <p className="absolute-b-text">{replaceTranslationVariables(t('dailyBonuses'), {"clubName": clubName})}</p>
                  </div>
              </div>
          </div>
          <div className="section-b v-5" id="playOnline">
              <div className="main-divider-row">
                  <div className="solutions-left-col bigger">
                      <div className="title-box bg-line">
                          <h3 className="title-text-v-3">{t('leagueGameScores')}</h3>
                      </div>
                    <p className="p-solutions-text">
                        {replaceTranslationVariables(t('leagueGames'), {"clubName": clubName})}
                    </p>
                  </div>
                  <div className="img-bg-container-v">
                      <div className="img-bd-league-games"/>
                  </div>
              </div>
              <div className="mini-bg-wrapper">
                  <div className="main-divider-row">
                      <div className="solutions-left-col">
                          <div className="games-icons-wrapper">
                              <div className="g-col-1">
                                  <h4 className="title-text-v-4 g-list-t">Games selection</h4>
                                  <div className="group-icons-b">
                                    <Feature color="1" icon={icon1} text={t('noLimitHoldem')} />
                                    <Feature color="2" icon={icon2} text={t('potLimitHoldem')} />
                                    <Feature color="3" icon={icon3} text={t('pinapple')} />
                                    <Feature color="4" icon={icon4} text={t('vegasStyleGames')} />
                                    <Feature color="7" icon={icon5} text={t('sportsPreMatchBetting')} />
                                    <Feature color="5" icon={icon6} text={t('sportsLiveBetting')} />
                                  </div>
                                  <h5 className="coming-soon-t">{t('comingSoon')}</h5>
                                  <div className="group-icons-b">
                                    <Feature color="6" icon={icon7} text="Backgammon" />
                                    <Feature color="6" icon={icon8} text="Rock Paper Scissors" />
                                  </div>
                              </div>
                              <div className="g-col-2">
                                  <h4 className="title-text-v-4 g-list-t">{t('excitingFeatures')}</h4>
                                  <div className="group-icons-b">
                                    <Feature color="7" icon={icon9} text={t('byhJackpot')} />
                                    <Feature color="8" icon={icon10} text={t('insurance')} />
                                    <Feature color="1" icon={icon11} text={t('tableDesignChoices')} />
                                    <Feature color="3" icon={icon12} text={t('playerStatistics')} />
                                    <Feature color="5" icon={icon13} text={t('handInfo')} />
                                    <Feature color="6" icon={icon14} text={t('amazingAnimations')} />
                                  </div>
                                  <h5 className="coming-soon-t">{t('comingSoon')}</h5>
                                  <div className="group-icons-b">
                                    <Feature color="6" icon={icon15} text={t('backgammon')} />
                                    <Feature color="6" icon={icon16} text={t('rockPaperScissors')} />
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="img-bg-container-v"/>
                  </div>
              </div>
          </div>
          {/*OR start*/}
          <div className="divider-line">
              <h3 className="line-title">
                  <span>{t('or')}</span>
              </h3>
          </div>
          {/*OR END*/}
          <div className="section-b v-6">

              <div className="main-divider-row left-img">
                  <div className="img-bg-container-v">
                      <div className="img-bd-private-club"/>
                  </div>
                  <div className="solutions-left-col bigger">
                      <div className="title-box bg-line">
                          <h3 className="title-text-v-3">{t(clubName)} {t('privateClubs')}</h3>
                      </div>
                      <p className="p-solutions-text">{t('privateClubsDesc')}</p>
                  </div>
              </div>
              <div className="mini-bg-wrapper right-radius">
                  <div className="b-title-container">
                      <h2 className="title-text-v-5">{t('readyToStart')}</h2>
                  </div>
                  <div className="button-container">
                      <a href="https://clubapp.clubdaddy.net/" target="_blank" onClick={openPokerApp} className="btn-v bg-color big">{t('playNow')}</a>
                  </div>
              </div>
          </div>
          <div className="section-b v-7">
              <div className="mini-bg-wrapper">
                  <div className="main-divider-row">
                      <div className="solutions-left-col">
                          <div className="title-box bg-line">
                              <InViewport addClassName="unbrand-me">
                                <h3 className="title-text-v-3">{t(clubName)} {t('brandedPartnerSolutions')}</h3>
                              </InViewport>
                          </div>
                          <p className="p-solutions-text">{replaceTranslationVariables(t('brandedPartnerSolutionsDesc'), {"clubName": clubName})}</p>
                          <a href="https://clubapp.clubdaddy.net/" target="_blank" className="btn-v bg-color big" id="becomePartner" onClick={openPokerApp}>{t('tryDemo')}</a>
                      </div>
                      <div className="img-bg-container-v">
                          <div className="img-bd-box"/>
                      </div>
                  </div>
                  <div className="become-a-partner-container">
                      <div className="b-ap-bg-container">
                          <div className="b-ap-content-box">
                              <div className="t-container-v">
                                  <h3 className="title-text-v-2">{t('becomePartner')}</h3>
                              </div>
                              <div className="grid-v">
                                  <div className="col-1">
                                      <p className="b-ap-form-text">{replaceTranslationVariables(t('tryDemoText'), {"clubName": clubName})}
                                        <br/><br/>
                                        {t('checkForDemo')}
                                       </p>
                                  </div>
                                  <div className="col-2">
                                      {/* TODO: Goes to a separate component */}
                                      <div className="become-form-box">

                                          <div className="bcm-f-row col-2">
                                              <div className="f-item-box">
                                                  <label className="f-item-title">{t('firstName')}</label>
                                                  <input className="input-v big full-w" type="text" name="firstName" onChange={onHandleChange}/>
                                              </div>
                                              <div className="f-item-box">
                                                  <label className="f-item-title">{t('lastName')}</label>
                                                  <input className="input-v big full-w" type="text" name="lastName"  onChange={onHandleChange}/>
                                              </div>
                                          </div>

                                          <div className="bcm-f-row">
                                              <div className="f-item-box">
                                                  <label className="f-item-title">{t('email')}</label>
                                                  <input className="input-v big full-w" type="Email" name="email" onChange={onHandleChange}/>
                                              </div>
                                          </div>

                                          <div className="bcm-f-row">
                                              <div className="f-item-box">
                                                <label className="f-item-title">{t('yourMessage')}</label>
                                                <textarea className="textarea-f-v" name="yourMessage" onChange={onHandleChange}/>
                                              </div>
                                          </div>
                                          <div className="button-container">
                                            <button className="btn-v bg-color big" onClick={sendFormToApi}>{t('send')}</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="section-b v-8">
              <div className="disclaimer-container">
                  <div className="disclaimer-content">
                      <h4 className="disclaimer-title-v">{t('leagueGamesScore')}</h4>
                      <p className="disclaimer-text-v">
                        {t(clubName)}{t('leagueGamesScoreDesc')}
                      </p>
                  </div>
              </div>
          </div>
      </div>
  );
}
export default Home;
