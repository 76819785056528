import React, {useState, useCallback } from 'react';
import { useTranslation } from 'core';
import config from '../../configs/config';
import gitVersion from '../../_git_commit';
import './footer.scss';
import {Link} from "react-router-dom";
import logo from "../../images/logo.png";
import useInput from "../../hook/useInput";

const Footer = ({menuNav}) => {
    const t = useTranslation('common');
    const [menuState, setMenuState] = useState('home');

    const [subscribeEmail, onSubscribeEmailChange] = useInput('');
    const gitInfo = gitVersion.logMessage ? gitVersion.logMessage : 'version is not defined for windows dev';
    
    const onMove = useCallback((to) => {
        setMenuState(to);
        menuNav(to);
    }, [setMenuState]);
    return (
        <footer className="App-footer">
            <div className="footer-row">
                <div className="f-social-row">
                    <div className="social-col">
                        <a href="#" className="social-link-f">
                            <i className="icon-fb15x"/>
                        </a>
                        <a href="#" className="social-link-f">
                            <i className="icon-insta15x"/>
                        </a>
                        <a href="#" className="social-link-f">
                            <i className="icon-insta-115x"/>
                        </a>
                        <a href="#" className="social-link-f">
                            <i className="icon-youtube15x"/>
                        </a>
                    </div>
                    <div className="app-link-col">
                        <div className="app-download-b">
                            <div className="ico-col-v">
                                <i className="icon-apple15x"/>
                            </div>
                            <p className="text-col-v">
                                <i className="s-title-v">{t('downloadOn')}</i>
                                <span className="store-name-v">{t('appStore')}</span>
                            </p>
                        </div>
                        <div className="app-download-b">
                            <div className="ico-col-v">
                                <i className="icon-android15x"/>
                            </div>
                            <p className="text-col-v">
                                <i className="s-title-v">{t('getItOn')}</i>
                                <span className="store-name-v">{t('googlePlay')}</span>
                            </p>
                        </div>
                        <div className="btn-v middle disabled">{t('comingSoon')}</div>
                    </div>
                </div>
                <div className="f-nav-row">
                    <div className="f-nav-col">
                        <div className="f-nav-item">
                            <a href="#" className="f-link-v">{t('aboutUs')}</a>
                        </div>
                        <div className="f-nav-item">
                            <a href="/terms" className="f-link-v">{t('termsOfService')}</a>
                        </div>
                        <div className="f-nav-item">
                            <a href="/privacy-policy" className="f-link-v">{t("privacyPolicy")}</a>
                        </div>
                    </div>
                    <div className="subscribe-col">
                        <input className="input-v medium" type="Email" placeholder="Email" onChange={onSubscribeEmailChange}/>
                        <button className="btn-v middle trans" onClick={() => console.log(subscribeEmail)}>{t('subscibe')}</button>
                    </div>
                </div>
                <div className="copyright-r">
                    <p className="copyright-text">{t('copyright')} {t(config.websiteName)}. {t('allRightsReserved')} | {t('version')} {gitInfo}</p>
                    <Link to="/" className="logo">
                        <img src={logo} alt=""/>
                    </Link>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

