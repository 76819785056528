import React from 'react';

const Feature = ({ color, icon, text }) => (
    <div className="single-icon-row">
        <div className={`icon-container-bg bg-color-${color}`}>
            <img src={icon} alt=""/>
        </div>
        <p className="g-ico-title">{text}</p>
    </div>
);

export default Feature;