import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation, translateJSON } from 'core';
import './t&c.scss';
import termsData from '../../assets/json/termsAndConditions.json';
import MenuItem from '../header/MenuItem.js'

const TermsAndConditions = () => {
    const [menuState, setMenuState] = useState(0);
    const onMove = useCallback((to) => {
        setMenuState(to);
    }, [setMenuState]);

    const t = useTranslation('common');

    return(
        <div className="terms-container">
            <div className="t-right-col">
                <div className="nav-container">
                    {termsData.terms.map((item,index)=>(
                        <MenuItem key={index} active={menuState === index} to="/terms" moveTo={index} onMove={onMove}>
                            {t(item.section)}
                        </MenuItem>)
                    )}
                </div>
            </div>
            <div className="t-left-col">
                <div className="terms-title">
                    <h1 className="title-text">{t('termsAndConditions')}</h1>
                    <p>{t('lastUpdated')} 1st of June 2020</p>
                    <p>{t('termsAndConditionsDesc')}</p>
                </div>
                <div className="terms-content">
                    {termsData.terms.map((item, index)=>(
                        <div id={index} key={index}>
                            <h2 className="section-title">{t(item.section)}</h2>
                            {!!item.text && item.text.map((text, index) => (
                                <p key={index} className="section-text">{t(text)}</p>
                            ))}
                            <ul>
                                {!!item.subSections && item.subSections.map((listItem) => (
                                    <li key={t(listItem.title)}>
                                        <span className="subsection-title">{t(listItem.title)}</span> {t(listItem.text)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default TermsAndConditions;
