import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './font-icons/style.css';
import App from './components/layout/App';
import * as serviceWorker from './serviceWorker';
import initPolyfills from './polyfills';

initPolyfills();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.unregister();
