import React, {useState, useCallback } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation, useLanguage } from 'core';
import MenuItem from './MenuItem';
import './header.scss';
import logo from '../../images/logo.png';
import config from '../../configs/config';

const Header = ({ menuNav }) => {
    const { pathname } = useLocation();
    const [menuState, setMenuState] = useState(pathname.substr(1) || "home");
    const [burgerActive, setBurgerActive] = useState(false);

    const [language, setLanguage] = useState(config.defaultLanguage);
    useLanguage(language);
    const t = useTranslation('common');

    const onMove = useCallback((to) => {
        setMenuState(to);
        setBurgerActive(false);
    }, [setMenuState, menuState]);

    return (
        <header className="App-header">
            <div className="header-row">
                <div className="h-left-col">
                    <MenuItem className="logo" moveTo="home" to="/" onMove={onMove}>
                        <img src={logo} alt="" />
                    </MenuItem>
                    <div className="nav-container">
                        {config.supportedTabs.map(tab=> 
                            <MenuItem key={tab} active={menuState === tab} to={tab == "faq" ? "/faq" : "/"} moveTo={tab} onMove={onMove}>{t(tab)}</MenuItem>
                        )}
                    </div>
                </div>
                <div className="h-right-col">
                    <div className="lang-sw active">
                        <p className="active-lang-v">{language}</p>
                        <div className="lang-drop-container">
                            <div className="lang-list-box">
                                {config.languages.map(lang=>
                                    <div className="drop-item-v" key={lang} onClick={() => setLanguage(lang)}>{t(lang)}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <MenuItem to="/contact-us" className="btn-v trans small" moveTo="contact" onMove={onMove}>{t('contactUs')}</MenuItem>
                </div>
            </div>
            {/*burger-nav start*/}
            <>
                <div className={`burger-navigation-container ${burgerActive ? 'active' : ''}`} onClick={() => setBurgerActive(!burgerActive)}>
                    <div className="burger-icon-box icon-burger15x"/>
                </div>
                <div className="m-nav-open-container">
                    <div className="nav-scroll-b-holder">
                        <div className="scroll-contain-v">
                            <div className="m-nav-content-b">
                                <div className="m-nav-link-b">
                                    <MenuItem active={menuState === 'home'} to="/" moveTo="home" onMove={onMove}><i className="icon-home-115x"/> {t('home')}</MenuItem>
                                    <MenuItem active={menuState === "contact"} to="/contact-us" moveTo="contact" onMove={onMove}><i className="icon-phone15x"/>{t('contactUs')}</MenuItem>
                                    <MenuItem active={menuState === "faq"} to="/faq" moveTo="faq" onMove={onMove}><i className="icon-15x"/> {t('faq')}</MenuItem>
                                </div>
                                <div className="language-burger-v">
                                    <h3 className="lang-nav-title"><i className="icon-language-115x"/>{t('language')}</h3>
                                    <div className="l-mini-list-b">
                                    {config.languages.map(lang=>
                                        <button className={language === lang ? 'active' : ''} key={lang} name={lang} onClick={() => setLanguage(lang)}>{t(`lang-${lang}`)}</button>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            {/*burger-nav END*/}
        </header>
  );
}

export default Header;
