import React, { useState, useMemo, useCallback } from 'react';
import { useTranslation, translateJSON } from 'core';
import './faq.scss';
import faqData from '../../assets/json/faq.json';
import FaqItem from './faqItem';

const Faq = () => {

    const [clubAppSelect, setClubAppSelect] = useState(0);
    const [partnerAccordionSelect, setPartnerAccordionSelect] = useState('');

    const t = useTranslation('common');

    const setActivation = useCallback((index) =>  {
        setClubAppSelect(index);
        setPartnerAccordionSelect('');
    },[setClubAppSelect]);

    const setPartnerSelection = useCallback((index) => {
        setPartnerAccordionSelect(index);
        setClubAppSelect('');
    },[setPartnerAccordionSelect])

  return (
      <div className="faq-container">
          <div className="page-content-holder">
              <div className="faq-content-holder">
                  <div className="p-title-holder">
                    <h1 className="p-title-text">{t('helpOffer')}</h1>
                    <p className="text-content-v">{t('selectTopic')}</p>
                  </div>
                  <div className="spoiler-holder">
                      <div className="sp-r-title-row">
                          <h2 className="sp-r-title-text">{t('clubApp')}</h2>
                      </div>
                    {
                        faqData.clubApp.map((item,index) => 
                            <FaqItem
                                key={index}
                                data={item}
                                select={() => setActivation(index)}
                                active={clubAppSelect ===  index}
                            />
                      )
                    }
                  </div>
                  <div className="spoiler-holder">
                      <div className="sp-r-title-row">
                          <h2 className="sp-r-title-text">{t('partnerSolutions')}</h2>
                      </div>
                      {
                        faqData.partnerSolutions.map((item,index) => 
                            <FaqItem
                                t={t}
                                key={index}
                                data={item}
                                select={() => setPartnerSelection(index)}
                                active={partnerAccordionSelect ===  index}
                            />
                      )
                    }

                  </div>
              </div>
          </div>
      </div>
  );
}
export default Faq;
