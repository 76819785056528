import React, { useState, useCallback } from 'react';
import { useTranslation } from 'core';
import './contacts.scss';

const  Contacts = () => {
    const t = useTranslation('common');

    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        yourMessage: ''
    });

    const onHandleChange = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value
        });
    };

    const sendFormToApi = useCallback(() => {
        console.log('send form info = ', formState );
    });

    const addContactImage = (e) => {
        var reader = new FileReader();
        let fileName = e.target.value.split("\\");
        fileName = fileName[fileName.length - 1].split(".")[0];
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
        reader.onload = function () {
            setTimeout(() => {
                setFormState(formState => ({
                    ...formState,
                    uploadedImage: reader.result
                })
                )
            }, 500)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

  return (
      <div className="contacts-container">
        <div className="contact-section-w">
            <div className="bg-contact-container">
                <div className="contact-h-row">
                    <h1 className="title-text-v-3 t-shadow">{t('contactUs')}</h1>
                    <p className="text-content-v">{t('contactUsText')}</p>
                </div>
                <div className="contact-f-container">
                    <div className="form-item-list-w">
                        <div className="c-f-i-row col-2-grid">
                            <div className="f-item-box">
                                <label className="f-item-title">{t('firstName')}</label>
                                <input className="input-v medium with-line full-w" type="text" name="firstName" onChange={onHandleChange}/>
                            </div>
                            <div className="f-item-box">
                                <label className="f-item-title">{t('lastName')}</label>
                                <input className="input-v with-line medium full-w" type="text" name="lastName"  onChange={onHandleChange}/>
                            </div>
                        </div>
                        <div className="c-f-i-row">
                            <div className="f-item-box">
                                <label className="f-item-title">{t('email')}</label>
                                <input className="input-v with-line medium full-w" type="Email" name="email" onChange={onHandleChange}/>
                            </div>
                        </div>
                        <div className="c-f-i-row">
                            <div className="f-item-box">
                                <label className="f-item-title">{t('yourMessage')}</label>
                                <textarea className="textarea-f-v radius-2 with-line" name="yourMessage" onChange={onHandleChange}/>
                            </div>
                        </div>

                        <div className="c-f-i-row ctc-button-r">
                            <div className="up-b-holder">
                                <div className="upload-btn-wrapper">
                                    <button className="btn-upload"><i className="icon-attach_file15x"/> {t('attachFile')}</button>
                                    <input type="file" name="myfile" onChange={addContactImage}/>
                                    {formState.uploadedImage ? (<div className={'uploadImage'} style={{backgroundImage: 'url(' + formState.uploadedImage + ')'}}></div>) : false}
                                </div>
                                <span className="limit-view">{t('maxSize')}</span>
                            </div>
                            <div className="f-item-box">
                                <button className="btn-v full-w bg-color middle" onClick={sendFormToApi}>{t('send')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
      </div>
  );
}
export default Contacts;
