import React, { useMemo } from 'react';
import { useTranslation, translateJSON } from 'core';
import './privacy.scss';
import policyData from '../../assets/json/privacyAndPolicy.json';

const Privacy = () => {
    const t = useTranslation('common');

    return(
        <div className="privacy-container">
            <div className="privacy-content-holder">
                <div className="privacy-header">
                    <h1 className="title-text">{t('cookiesPolicy')}</h1>
                    <p className="last-updated">{t('lastUpdated')} June 11, 2020</p>
                    <p>{t('cookiesPolicyDesc1')}</p>
                    <p>{t('cookiesPolicyDesc2')}</p>
                    <p>{t('cookiesPolicyDesc2')}</p>
                </div>
                <div className="privacy-content">
                    <h2 className="section-header">{t('interpretationsAndDefinitions')}</h2>
                    {policyData.definitions.map((item) => (
                        <div key={item}>
                            <h2 className="section-title-text">{t(item.section)}</h2>
                            <p className="section-text">{t(item.text)}</p>
                            <ul>
                                {!!item.subSections && item.subSections.map((listItem, index) => (
                                    <li key={index}>
                                        {t(listItem.title)} {t(listItem.text)} {!!listItem.link && <a href={listItem.link}>{listItem.link}</a>}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                <h2 className="section-header">{t('useOfCookies')}</h2>
                    {policyData.useOfCookies.map((item, index) => (
                        <div key={index}>
                            <h2 className="section-title-text">{t(item.section)}</h2>
                            <p className="section-text">{t(item.text)}</p>
                            <ul>
                                {!!item.subSections && item.subSections.map((listItem, index) => (
                                    <li key={index}>
                                        <span className="subsection-title">{t(listItem.title)}</span> {t(listItem.text)} 
                                        {!!listItem.link && <a target="_blank" href={listItem.link}>{t(listItem.link)}</a>}
                                    </li>
                                ))}
                            </ul>
                            {!!item.cookyTypes && item.cookyTypes.map((type, index) => (
                                <div className="cookies" key={index}>
                                    <h2>{t(type.title)}</h2>
                                    <p>{t(type.type)}</p>
                                    <p>{t(type.administratedBy)}</p>
                                    <p>{t(type.purpose)}</p>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Privacy;
