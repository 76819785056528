import React from 'react';
import InViewportInner from './InViewportInner';
import TrackVisibility from 'react-on-screen';

const InViewport = ({ children, addClassName, once }) => (
    <TrackVisibility>
        <InViewportInner addClassName={addClassName}>
            {children}
        </InViewportInner>
    </TrackVisibility>
);

export default InViewport;