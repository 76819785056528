import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const HEADER_HEIGHT = 75;

const MenuItem = ({ to, moveTo, className, active, children, onMove }) => {
  const onClick = useCallback(() => {
    const node = document.getElementById(moveTo);

    if (node) {
      const nodeHeight =
        node.getBoundingClientRect().top + window.pageYOffset - HEADER_HEIGHT;
      console.log('headerHeight'.headerHeight);

      window.scrollTo({ top: nodeHeight, behavior: 'smooth' });
    }
    if (onMove) onMove(moveTo);
  }, [moveTo, onMove]);

  let classes = className || 'nav-link';
  if (active) classes += ' active';

  return (
    <Link to={to} className={classes} onClick={onClick}>
      {children}
    </Link>
  );
};

export default MenuItem;
